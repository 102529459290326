<!-- 结算单列表 -->
<template>
  <div>
    <SearchPanel
      flex
      show-btn
      @getList="getSearchList"
    >
      <div>
        <p>结算ID</p>
        <el-input
          v-model="settlementNo"
          size="mini"
          type="number"
          clearable
          placeholder="请输入结算单号"
        />
      </div>
      <!-- <div>
        <p>订单编号</p>
        <el-input
          v-model="orderNo"
          size="mini"
          clearable
          placeholder="请输入订单编号"
        />
      </div> -->
      <div>
        <p>提现ID</p>
        <el-input
          v-model="withdrawId"
          size="mini"
          type="number"
          placeholder="请输入提现ID"
        />
      </div>
      <div>
        <p>结算单状态</p>
        <el-select
          v-model="settlementStatus"
          size="mini"
          clearable
        >
          <el-option
            v-for="(item,index) in settlementStatusArr"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <p>结算时间</p>
        <el-date-picker
          v-model="dateTime"
          size="mini"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="download('export')"
      >
        导出结算单
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        @click="download('goods')"
      >
        导出结算单明细
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8', 'color': '#333'}"
      element-loading-text="拼命加载中"
      border
      style="width: 100%"
    >
      <el-table-column
        prop="supplyWithdrawId"
        label="提现ID"
      />
      <el-table-column
        prop="supplySettlementId"
        label="结算ID"
      />
      <el-table-column
        prop="purchaseAmount"
        label="订货金额"
      />
      <el-table-column
        prop="refundAmount"
        label="订货退货金额"
      />
      <el-table-column
        prop="actuallyAmount"
      >
        <template #header>
          实际金额
          <el-popover
            placement="right-start"
            title=""
            width="300"
            trigger="hover"
          >
            <div class="wait">
              实际金额=已确认的对账单中订货单的订货金额-订货退货金额
            </div>
            <i
              slot="reference"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="checkAmount"
        label="对账金额"
      /> -->
      <el-table-column
        prop="checkAmount"
      >
        <template #header>
          结算金额
          <el-popover
            placement="right-start"
            title=""
            width="300"
            trigger="hover"
          >
            <div class="wait">
              结算金额=已确认的对账单的对账金额
            </div>
            <i
              slot="reference"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="settlementStatusName"
        label="结算状态"
      />
      <el-table-column
        prop="auditTime"
        label="结算时间"
      >
        <template slot-scope="scope">
          {{ setTime(scope.row.auditTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';

import { downloadFile } from '@/utils/download';

export default {
  name: 'settlement-list-component',
  components: {

  },
  data() {
    return {
      tableData: [],
      loading: false,
      settlementNo: null,
      orderNo: null,
      dateTime: null,
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      withdrawId: null, // 提现id
      settlementStatus: null,
      settlementStatusArr: [
        // {
        //   label: '待结算',
        //   value: 1,
        // },
        {
          label: '可提现',
          value: 2,
        },
        {
          label: '待打款',
          value: 3,
        },
        {
          label: '已提现',
          value: 4,
        },
      ], // 状态
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 点击搜索
    getSearchList() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: this.$api.supplierSettlement.settlementPage,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          supplySettlementId: this.settlementNo,
          supplyWithdrawId: this.withdrawId,
          auditStartTime: this.dateTime ? this.dateTime[0] : null,
          auditEndTime: this.dateTime ? this.dateTime[1] : null,
          settlementStatus: this.settlementStatus,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.pagination.count = Number(res.data.total);
        } else {
          this.$message(res.error_msg);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    download(type) {
      this.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
      let api = this.$api.supplierSettlement.downloadSettlement;
      let name = '结算单数据.xls';
      if (type === 'goods') {
        api = this.$api.supplierSettlement.download;
        name = '结算单明细数据.xls';
      }
      this.$axios({
        method: 'post',
        url: api,
        data: {
          // currentPage: this.pagination.currentPage,
          // pageSize: this.pagination.nowPageSize,
          supplySettlementId: this.settlementNo, // 结算单id
          supplyWithdrawId: this.withdrawId, // 提现id
          auditStartTime: this.dateTime ? this.dateTime[0] : null, // 结算时间
          auditEndTime: this.dateTime ? this.dateTime[1] : null, // 结算时间
          settlementStatus: this.settlementStatus, // 结算状态
        },
        responseType: 'blob',
      }).then((res) => {
        downloadFile(res, name);
      });
    },
    setTime(time) {
      return time ? dayjs(time).format('YYYY/MM/DD HH:mm:ss') : '';
    },
  },
};
</script>
