import { Message } from 'element-ui';

export function downloadFile(file, fileName, failCallback) {
  // 下载失败函数归一化
  if (failCallback === undefined || typeof failCallback !== 'function') {
    failCallback = (res) => {
      if (res.code !== 0) Message.error(res.msg);
    };
  }
  // 转成Blob
  const blob = new Blob([file]);
  // 转成字符串
  const reader = new FileReader();
  reader.onload = (res) => {
    try {
      // 如果可以转成对象，说明返回的是报错信息对象，提示报错
      const { result } = res.target;
      const data = JSON.parse(result);
      failCallback(data);
    } catch (err) {
      // 不能转成对象的是文件流
      download(blob);
    }
  };
  reader.readAsText(blob, 'utf-8');

  function download(blob) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
    URL.revokeObjectURL(a.href);
  }
}
