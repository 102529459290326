<template>
  <div class="app-container">
    <div class="page-header">
      <div
        v-for="(nav, index) in headerList"
        :key="index"
        :class="nav.is_select === 1 ? 'is-select' : ''"
        @click="selectNav(nav)"
      >
        {{ nav.title }}
      </div>
    </div>
    <div
      class="outer"
      style="margin-top:20px;"
    >
      <!-- 结算版块 -->
      <div
        v-show="headerList[1].is_select === 1"
        class="settlement"
      >
        <SettlementListComponent />
      </div>
      <div
        v-show="headerList[0].is_select === 1"
        class="withdraw"
      >
        <div class="title">
          申请提现
        </div>
        <!-- <div class="wait">
          <span>待结算金额：</span><span>￥{{ unsettlementAmount }}</span>
        </div> -->
        <div class="total">
          <span>可提现金额：</span><span>￥{{ unwithdrawAmount }}</span>

          <el-popover
            title=""
            width="300"
            trigger="hover"
          >
            <div class="wait">
              订货单已结算已开票的金额，即供应链月度对账单已确认完毕且已开票的金额合计
            </div>
            <i
              slot="reference"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>

          <div class="freeze">
            <span>冻结金额：</span><span>￥{{ frozenAmount }}</span>
            <el-popover
              title=""
              width="300"
              trigger="hover"
            >
              <div class="wait">
                供应链月度对账单已确认完毕但未开票的金额合计
              </div>
              <i
                slot="reference"
                class="el-icon-warning-outline"
              ></i>
            </el-popover>
          </div>
        </div>

        <div class="wait">
          <span>提现中金额：</span>
          <span>￥{{ withdrawingAmount }}</span>
        </div>
        <div class="acc">
          <span>累计提现金额：</span>
          <span>￥{{ totalWithdrawAmount }}</span>
        </div>
        <div class="btn">
          <el-button
            type="primary"
            @click="playWithdrawDeposit"
          >
            立即提现
          </el-button>
          <p>注：上一笔提现到账后，可再次申请提现。财务打款时间为每周二、周四，若遇到问题请联系工作人员</p>
        </div>
        <div class="select-tab">
          <div
            v-for="tab in tabList"
            :key="tab.title"
            :class="tab.is_select === 1 ? 'is-select' : ''"
            @click="selectTab(tab)"
          >
            {{ tab.title }}
            <span v-show="tab.is_select === 1"></span>
          </div>
        </div>
        <div
          v-show="tabList[0].is_select === 1"
          style="margin-top:20px;"
        >
          <withdraw-list-component ref="withDrawList" />
        </div>
        <div
          v-show="tabList[1].is_select === 1"
          style="margin-top:20px;"
        >
          <account-list-component />
        </div>
        <!-- <div
          v-show="tabList[2].is_select === 1"
          style="margin-top:20px;"
        >
          <jd-list-component />
        </div>
        <div
          v-if="tabList[3].is_select === 1"
          style="margin-top:20px;"
        >
          <MembershipCard />
        </div> -->
      </div>
    </div>

    <el-dialog
      id="dialog"
      title=""
      custom-class="saw-notice-box"
      width="795px"
      :close-on-click-modal="false"
      :visible.sync="noticeOff"
      @closed="closedDialog"
    >
      <div class="title">
        网上农博运费结算协议
      </div>
      <div class="content">
        一、“网上农博商家”在本平台直接下快递单，通过京东物流发货所产生的快递费由京东物流公司与网上农博平台统一结算。<br />
        二、网上农博平台代为缴纳快递费后，按实际缴纳金额向各商家收取快递费。<br />
        三、“网上农博商家”同意“网上农博平台”从各商家每月可结算的货款中先行扣除上月的快递费用，各商家对扣除快递费的余额部分按平台规则进行提现。
      </div>
      <div class="btn-box">
        <div class="check-box">
          <el-checkbox v-model="checked">
            我已阅读并同意 《网上农博运费结算协议》
          </el-checkbox>
          <div
            v-show="errOff && !checked"
            class="check-err"
          >
            请先阅读并同意
          </div>
        </div>
        <el-button
          type="primary"
          :loading="toWithDrawLoad"
          @click="toWithDraw"
        >
          确认提现
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import { confirm } from '@/api/confirmUrl';
import SettlementListComponent from './components/settlementListComponent.vue';
import accountListComponent from './components/accountListComponent.vue';
import withdrawListComponent from './components/withdrawListComponent.vue';
import { dataURItoBlob } from '@/utils/common';

export default {
  name: 'settlement-withdrawal',
  components: {
    SettlementListComponent,
    accountListComponent,
    withdrawListComponent,
  },
  data() {
    return {
      headerList: [{ title: '提现', is_select: 1 }, { title: '结算', is_select: 0 }],
      codeSn: '',
      startTime: '',
      endTime: '',
      paginationSet: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      loadingSet: false,
      tableDataSet: [],
      tabList: [
        { title: '提现记录', is_select: 1 },
        { title: '账户资金明细', is_select: 0 },
        // { title: '京东运费明细', is_select: 0 },
        // { title: '会员卡充值', is_select: 0, id: 4 },
      ],
      itemDataRecord: [
        { prop: 'id', label: '序号' },
        { prop: 'created_at', label: '时间' },
        { prop: 'amount', label: '金额' },
        { prop: 'status', label: '状态' },
      ],
      // itemDataBalance: [
      //   { prop: 'id', label: '序号' },
      //   { prop: 'created_at', label: '操作时间' },
      //   { prop: 'amount', label: '收/支' },
      //   { prop: 'balance', label: '可提现余额' },
      //   { prop: 'remark', label: '备注' }
      // ],
      balanceMoney: '',
      unwithdrawAmount: '',
      // unsettlementAmount: '',
      totalWithdrawAmount: '',
      withdrawingAmount: '',
      frozenAmount: '',
      withdrawCode: '',
      // jdSettlementAmount: '',
      // settlementAmount: '',
      loadingBalance: false,
      tableDataBalance: [],
      paginationBalance: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      supplierId: '',
      settlementInfo: '',
      checked: false,
      errOff: false,
      noticeOff: false,
      toWithDrawLoad: false,
      // isMembershipCardCheckStore: false, // 是否农博优选店铺
    };
  },
  mounted() {
    this.supplierId = JSON.parse(localStorage.getItem('userInfo')).suppliersId;

    this.getSupplierMoney();
    // this.getBalanceList()
    // this.membershipCardCheckStore(); // 判断是否农博优选店铺
  },
  methods: {
    playWithdrawDeposit() {
      if (this.settlementInfo.tradeWithdrawBlacklistDto) {
        this.$alert(`当前无法申请提现，原因：${this.settlementInfo.tradeWithdrawBlacklistDto?.reason}`, '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: () => {
          },
        });
      } else {
        // this.noticeOff = true;
        // 跳过运费协议确认,直接跳转到提现页面
        this.checked = true;
        this.toWithDraw();
      }
    },
    // membershipCardCheckStore() {
    //   this.$axios.get(this.$api.settlement.membership_checkStore).then((r) => {
    //     if (r.code === 0) {
    //       this.isMembershipCardCheckStore = r.data;
    //     }
    //   });
    // },
    initImg(url, outPutFormat = 'image/png') {
      return new Promise((resolve) => {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.crossOrigin = '*';
        img.src = `${url}?timeStamp=${Date.now()}`;
        img.onload = function () {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);

          const base64Url = canvas.toDataURL(outPutFormat);
          canvas = null;
          resolve(base64Url);
        };
      });
    },
    closedDialog() {
      this.checked = false;
      this.errOff = false;
    },
    // 生成快照
    convertToImage(container, options = {}) {
      // 设置放大倍数
      const scale = window.devicePixelRatio;

      // 传入节点原始宽高
      const conWidth = container.offsetWidth;
      const conHight = container.offsetHeight;

      let { width, height } = options;
      width = width || conWidth;
      height = height || conHight;

      // html2canvas配置项
      const ops = {
        scale,
        width,
        height,
        useCORS: true,
        allowTaint: false,
        ...options,
      };

      return html2canvas(container, ops).then((canvas) => {
        return canvas.toDataURL('image/png');
      });
    },
    // 上传图片接口
    async uploadOssApi(data) {
      let params = new FormData();
      params.append('multipartFile', data);
      return this.$axios({
        url: this.$api.nb_common.newUpload,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: params,
      });
    },
    // 切换顶部导航
    selectNav(nav) {
      // 不使用 i++ 的方式，防止数组越界
      for (let i = 0; i < this.headerList.length; i += 1) {
        this.headerList[i].is_select = 0;
      }
      // nav.is_select = 1;
      // 使用vue.set()方法，
      this.$set(nav, 'is_select', 1);
    },
    // 时间改变
    changesTime() {
      if (this.startTime !== null) {
        this.sTime = Date.parse(this.startTime) / 1000;
      } else {
        this.sTime = '';
      }
    },
    selectTab(tab) {
      for (let i = 0; i < this.tabList.length; i += 1) {
        this.tabList[i].is_select = 0;
      }
      // tab.is_select = 1;
      this.$set(tab, 'is_select', 1);
    },
    getSupplierMoney() {
      this.$axios.get(this.$api.supplierRegister.myBalance, {}).then((r) => {
        if (r.code === 0) {
          this.settlementInfo = r.data ? r.data : {
            unWithdrawAmount: '0.00',
            withdrawingAmount: '0.00',
            totalWithdrawAmount: '0.00',
            withdrawCode: 0,
            supplySettlementIds: [],
          };
          this.frozenAmount = r.data?.frozenAmount || '0.00'; // 冻结金额
          this.unwithdrawAmount = r.data?.unWithdrawAmount || '0.00'; // 可提现金额
          this.withdrawingAmount = r.data?.withdrawingAmount || '0.00'; // 提现中的金额
          this.totalWithdrawAmount = r.data?.totalWithdrawAmount || '0.00'; // 累计提现金额
          this.withdrawCode = r.data?.withdrawCode || 0;
        }
      });
    },
    async toWithDraw() {
      const that = this;
      if (!this.checked) {
        this.errOff = true;
        return false;
      }
      this.errOff = false;

      if (this.unwithdrawAmount <= 0) {
        that.$message.warning('可提现金额不足~');
      } else if (Number(that.withdrawCode) === 1) {
        that.$message.warning('提现流程待审核~');
      } else if (Number(that.withdrawCode) === 4) {
        that.$message.warning('正在提现中，请勿重复操作~');
      } else if (Number(that.withdrawCode) === 3) {
        that.$message.warning('提现审核不通过~');
      } else if (Number(that.withdrawCode) === 2) {
        that.$alert(`
            <div>
              <p style="font-size: 16px">您当前的待提现金额为：${that.unwithdrawAmount}元，是否需要申请提现？<p>
              <strong style="color: red">注：提现成功后，数据更新存在延迟，请尝试手动刷新页面查看最新数据</strong>
            </div>
          `, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
        }).then(async (res) => {
          console.log(res, '========', this.settlementInfo);
          this.toWithDrawLoad = true;

          // 全屏截图，处理用户头像
          const image = document.querySelector('.user-avatar');
          const imageBase64 = document.querySelector('.user-avatar2');
          const url = image.getAttribute('src');
          const src = await this.initImg(url);
          imageBase64.setAttribute('src', src);
          imageBase64.setAttribute('style', 'display: inline-block');
          image.setAttribute('style', 'display: none');

          // 截图
          const imgBase64 = await this.convertToImage(document.querySelector('body'));
          const ossRes = await this.uploadOssApi(new File([dataURItoBlob(imgBase64)], `${Date.now()}.png`));

          that.$axios.post(
            that.$api.supplierRegister.withdrawAdd,
            {
              // applicant: this.settlementInfo.supplierName,
              // settlementIds: this.settlementInfo.settlementIds,
              // supplierId: this.settlementInfo.supplierId,
              // withdrawAmount: this.settlementInfo.unwithdrawAmount,
              supplySettlementIds: this.settlementInfo.supplySettlementIds,
              image: ossRes.data.fileUrl,
            },
          ).then((r) => {
            this.toWithDrawLoad = false;
            if (r.code === 0) {
              this.$refs.withDrawList.getList();
              this.getSupplierMoney(); // 获取余额
              that.$message.success('提现成功');
              this.noticeOff = false;
            }
          });
        }).catch((err) => {
          this.toWithDrawLoad = false;
          console.log(err);
        });
      } else {
        that.$message.warning('收款信息不完善~');
      }
      return true;
    },
    download() {
      // 下载
      let url = `${this.$api.closeOrder.settlement_export}?settlement_sn=${this.codeSn}&settlement_stime=${this.sTime}&settlement_etime=${this.eTime}&page=${this.paginationSet.currentPage}&limit=${this.paginationSet.nowPageSize}`;
      confirm('是否确认导出数据', url);
    },
    handleSizeChangeBalance(val) {
      this.paginationBalance.nowPageSize = val;
      this.paginationBalance.currentPage = 1;
      this.getBalanceList();
    },
    handleCurrentChangeBalance(val) {
      this.paginationBalance.currentPage = val;
      this.getBalanceList();
    },
  },
};
</script>

<style lang="scss">
.saw-notice-box{
  .title{margin-bottom: 20px; font-size: 20px; text-align: center;}
  .content{line-height: 24px; margin-bottom: 20px;}
  .btn-box{display: flex; flex-direction: column; align-items: center; justify-content: center;}
  .check-box{height: 32px;}
  .el-button{width: 150px; margin-top: 20px;}
  .check-err{padding-left: 24px; font-size: 12px; color: red;}
  .el-checkbox__inner{top: -1.6px;}
}
</style>

<style lang="scss" scoped>
  .page-header {
    width: 100%;
    height: 56px;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 94px;
      height: 30px;
      border: 1px solid #D9DDE1;
      font-size:14px;
      font-family: Microsoft YaHei;
      color: #C5C8CA;
      text-align: center;
      line-height: 30px;
      overflow: hidden;
      transition: 0.3s all;
      cursor: pointer;
    }
    div:nth-of-type(1) {
      border-right: 0;
      border-radius: 5px 0 0 5px;
    }
    div:nth-of-type(2) {
      border-left: 0;
      border-radius: 0 5px 5px 0;
    }
    .is-select {
      background: #3FB265;
      border-color: #3FB265;
      color: #fff;
    }
  }
  .withdraw {
    overflow: hidden;
    .title {
      color:#333;
      font-size: 24px;
      font-weight: 600;
      line-height: 50px;
    }
    .total {
      line-height: 30px;
      display: flex;
      align-items: center;
      .freeze {
        margin-left: 150px;
      }
      span:nth-of-type(1) {
        font-size: 16px;
        color: #333;
      }
      span:nth-of-type(2) {
        font-size: 20px;
        color: red;
        font-weight: bolder;
      }
    }
    .wait {
      line-height: 30px;
      span:nth-of-type(1) {
        color: #333;
        font-size: 16px;
      }
      span:nth-of-type(2) {
        color: #333;
        font-size: 20px;
        font-weight: bolder;
      }
    }
    .acc {
      line-height: 30px;
      span:nth-of-type(1) {
        color: #333;
        font-size: 16px;
      }
      span:nth-of-type(2) {
        color: #333;
        font-size: 20px;
        font-weight: bolder;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      margin-top: 20px;
      p {
        font-size: 16px;
        margin-left: 30px;
        color: red;
        font-weight: bold;
      }
    }
    .select-tab {
      height:40px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      div {
        height: 100%;
        position: relative;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        margin-right: 30px;
        cursor: pointer;
        span {
          width: 30px;
          height: 2px;
          background:#409EFF;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
      .is-select {
        font-size: 15px;
        font-weight: 600;
        color:#409EFF;
      }
    }
  }
.el-icon-warning-outline{margin-left: 10px; font-size: 15px;}
</style>
