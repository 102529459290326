
// 下载和导出的二次确认弹框
import { MessageBox } from 'element-ui'

export function confirm(message = '', url = '') {
  MessageBox.confirm(message + '?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    location.href = url
  })
}
